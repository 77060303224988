import Comment from "../../components/Comment/Comment";
import Avatar1 from "../../assets/images/svg/Avatar1.svg";
import Avatar2 from "../../assets/images/svg/Avatar2.svg";
import Avatar3 from "../../assets/images/svg/Avatar3.svg";
import Avatar4 from "../../assets/images/svg/Avatar4.svg";
import Avatar5 from "../../assets/images/svg/Avatar5.svg";
import Avatar6 from "../../assets/images/svg/Avatar6.svg";

export const Testimonials = [
    <Comment
      avatar={Avatar1}
      name='Vijay Murphy'
      message='Lunaa is a must tool for every content creator. Being able to determine what content works, The most critical problem it solves is to be able to understand the metrics of growth of our personal branding efforts.'
      role='Founder'
    />,
    <Comment
      avatar={Avatar2}
      name='Kathleen Stafford'
      message='I was facing difficulty tracking the engagement rate of my posts and understanding what kind of posts my target audience liked better. Shield helped me figure that out.'
      role='Consultant'
    />,
    <Comment
      avatar={Avatar3}
      name='Carter Martin'
      message='Lunaa provides structured version of LinkedIn analytics on personal profiles. It is highly helpful.'
      role='B2B Content marketer'
    />,
    <Comment
      avatar={Avatar4}
      name='Ricardo Mack'
      message={`Since using Lunaa, I have a MUCH better understanding of what content my audience loves and what content falls flat. As a result, I've been able to fine-tune my approach to "give the people what they want." Since using Lunaa, my content views, engagements, engagement rate and follower base are all up!`}
      role='Social media marketing'
    />,
    <Comment
      avatar={Avatar5}
      name='Edgar Torrey'
      message={`I can see which of my personal posts are performing and which are lacking. More than anything, though, it's just lovely to see my progress and be able to provide some success metrics for the work I'm doing on LinkedIn.`}
      role='Helping Founders create personal brands'
    />,
    <Comment
      avatar={Avatar6}
      name='Lukas Duran'
      message='Lunaa provides structured version of LinkedIn analytics on personal profiles. It is highly helpful.'
      role='Ghostwriter, content creator'
    />,
  ];