import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/svg/logo.svg";
import { useUserAuth } from "../../auth/AuthContext/AuthContext";
import Button from "../Button/Button";
import styles from "./navbar.module.scss";

const Navbar = () => {
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <nav>
      <div id={styles["nav-logo-section"]} className={styles["nav-section"]}>
        <a href='/'>
          <img src={Logo} alt='logo' />
        </a>
      </div>
      <div id={styles["nav-mobile-section"]}>
        {/* <div
          id={styles["nav-social-section"]}
          className={styles["nav-section"]}
        ></div>
        <div
          id={styles["nav-contact-section"]}
          className={styles["nav-section"]}
        >
        </div> */}
      </div>
      <div className={styles["nav-section"]}>{user && <Button onClick={handleLogout} size="small">Log out</Button>}</div>
    </nav>
  );
};

export default Navbar;
