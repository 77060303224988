import React from "react";

import styles from "./comments.module.scss";

interface CommentsProps {
  comments: React.ReactNode[];
}

const Comments = ({ comments }: CommentsProps) => {
  let i = 0;
  return (
    <div className={styles.comments}>
      {comments.map((comment) => (
        <div key={`comment-${i++}`}>{comment}</div>
      ))}
    </div>
  );
};

export default Comments;
