import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../auth/AuthContext/AuthContext";

interface ProtectedRouteProps {
  children?: React.ReactNode;
}

const PublicRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user } = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && Object.keys(user).length !== 0) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return <>{children}</>;
};

export default PublicRoute;
