import React from "react";

import styles from "./form.module.scss";
import Logo from "../../assets/images/svg/logo.svg";

interface FormProps {
  children?: React.ReactNode;
}

const Form = ({ children }: FormProps) => {
  return (
    <div className={styles.form}>
      <a href='/' className={styles.login__logo}>
        <img src={Logo} alt='logo' />
      </a>
      <form className={styles.form__content}>{children}</form>
    </div>
  );
};

export default Form;
