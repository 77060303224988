import React from "react";
import { TextFieldEnum } from "../../core/text-field";

import styles from './TextField.module.scss';

const emailPlaceholder = "Enter your email address...";
const passwordPlaceholder = "Enter your password...";

interface TextFieldProps {
    type: TextFieldEnum.Email | TextFieldEnum.Password;
    error?: boolean
    errorMessage?: string
    onChange?: (e: any) => void;
}

const TextField = (props: TextFieldProps) => {
  return (
    <div className={styles.textFieldGroup}>
      <label htmlFor={props.type} className={styles.textFieldLabel}>
        {props.type}
      </label>
      <input
        type={props.type}
        id={props.type}
        className={props.error ? styles.textField__error : styles.textField}
        placeholder={props.type === TextFieldEnum.Email ? emailPlaceholder : passwordPlaceholder}
        onChange= {props.onChange}
      />
      {props.error && <p className={styles.textField__error_message}>{props.errorMessage}</p>}
    </div>
  );
};

export default TextField;
