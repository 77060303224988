import React from "react";
import styles from "./loading.module.scss";

import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

const Loading = () => {
  return (
    <div className={styles.Loading}>
      <Stack sx={{ color: "white" }} spacing={2} direction='row'>
        <CircularProgress color='inherit' />
      </Stack>
    </div>
  );
};

export default Loading;
