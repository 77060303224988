import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import GoogleButton from "react-google-button";

import styles from "./login.module.scss";
import { useUserAuth } from "../../auth/AuthContext/AuthContext";
import TextField from "../TextField/TextField";
import { TextFieldEnum } from "../../core/text-field";
import Button from "../Button/Button";
import { toast } from "react-toastify";
import Form from "../Form/Form";
import { checkError } from "../../core/error";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [inlineError, setInlineError] = useState(false);
  const { logIn, googleSignIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setError("");
    setInlineError(false);
    try {
      await logIn(email, password);
      navigate("/dashboard");
    } catch (err: any) {
      setInlineError(true);
      setError(checkError(err));
    }
  };

  const handleGoogleSignIn = async (e: any) => {
    e.preventDefault();
    setError("");
    setInlineError(false);
    try {
      await googleSignIn();
      navigate("/dashboard");
    } catch (error: any) {
      toast.error("Oops! Something went wrong.", {
        toastId: "google-login-error",
      });
      console.log(error.message);
    }
  };

  return (
    <div className={styles.login}>
      <Form>
        <h3>Sign in to get started</h3>
        <div className={styles.g__btn_container}>
          <GoogleButton
            className={styles.g__btn}
            type='light'
            onClick={handleGoogleSignIn}
            label='Sign in with Google'
          />
          <div className={styles.separate}>
            <hr /> or <hr />
          </div>
        </div>
        <TextField
          type={TextFieldEnum.Email}
          error={inlineError}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          type={TextFieldEnum.Password}
          error={inlineError}
          errorMessage={error}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className={styles.submit}>
          <p>
            Don't have an account? <Link to='/register'>Register</Link> now.
          </p>
          <Button onClick={handleSubmit} size='regular'>
            Login <span className={styles.arrow}>&nbsp; &#8594;</span>
          </Button>
        </div>
        <p>
          <Link to='/reset'>Forgot Password</Link>
        </p>
      </Form>
    </div>
  );
};

export default Login;
