import React from "react";

import styles from "./comment.module.scss";

interface CommentProps {
  avatar: string;
  name: string;
  role: string;
  message: string;
}

const Comment = ({ avatar, name, role, message }: CommentProps) => {
  return (
    <div className={styles.comment}>
      <div className={styles.comment__header}>
        <img className={styles.avatar} alt={role} src={avatar} />
        <div>
          <div className={styles.name}>{name}</div>
          <div className={styles.role}>{role}</div>
        </div>
      </div>
      <div className={styles.message}>{message}</div>
    </div>
  );
};

export default Comment;
