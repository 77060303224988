import React, { useState } from "react";
import TextField from "../TextField/TextField";
import { TextFieldEnum } from "../../core/text-field";
import { Link, useNavigate } from "react-router-dom";
import GoogleButton from "react-google-button";

import styles from "./Registration.module.scss";
import { useUserAuth } from "../../auth/AuthContext/AuthContext";
import Button from "../Button/Button";
import { toast } from "react-toastify";
import Form from "../Form/Form";
import { checkError } from "../../core/error";
import { validateEmailAndPassword } from "../../core/credentials-validate";

const Registration = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [inlineError, setInlineError] = useState(false);
  const { signUp, googleSignIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setError("");
    try {
      validateEmailAndPassword(email, password);
      await signUp(email, password);
      navigate("/dashboard");
    } catch (err: any) {
      setInlineError(true);
      setError(checkError(err));
    }
  };

  const handleGoogleSignIn = async (e: any) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate("/dashboard");
    } catch (error: any) {
      toast.error("Oops! Something went wrong.", {
        toastId: "google-registration-error",
      });
      console.log(error.message);
    }
  };

  return (
    <div className={styles.registration}>
      <Form>
        <h3>Sign up to get started</h3>
        <div className={styles.g__btn_container}>
          <GoogleButton
            className={styles.g__btn}
            type='light'
            onClick={handleGoogleSignIn}
            label='Sign up with Google'
          />
          <div className={styles.separate}>
            <hr /> or <hr />
          </div>
        </div>
        <div className={styles.registrationForm}>
          <TextField
            type={TextFieldEnum.Email}
            error={inlineError}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            type={TextFieldEnum.Password}
            error={inlineError}
            errorMessage={error}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className={styles.submit}>
          <p className={styles.registration__txt}>
            Already got an account? <Link to='/'>Login</Link>
          </p>
          <Button onClick={handleSubmit} size='regular'>
            Continue <span className={styles.arrow}>&nbsp; &#8594;</span>
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Registration;
