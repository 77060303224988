import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from 'firebase/functions';

import { getAuth } from "firebase/auth";
import { AppConfig } from "../config/config";

// Initialize Firebase
const app = initializeApp(AppConfig.firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const functions = getFunctions(app, 'europe-west2');

export { app, auth, analytics, functions };
