import React from "react";

import styles from "./button.module.scss";

interface ButtonProps {
  size?: "small" | "regular" | "large";
  icon?: string;
  children?: React.ReactNode;
  onClick?: (e: any) => void;
}

const Button: React.FC<ButtonProps> = ({
  size,
  icon,
  children,
  onClick,
}) => {
  return (
    <button className={styles[`button-${size}`]} onClick={onClick}>
      {icon && <img src={icon} alt='icon' />}
      <div className={styles.btn__contents}>{children}</div>
    </button>
  );
};

export default Button;
