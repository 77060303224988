import { LinkedinProfile } from "./user";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../auth/firebase";
import { UserProfile, LinkedinUserProfile } from "../../core/user";

const updateUser = httpsCallable(functions, "updateUser");
export const updateUserProfile = async (linkedInCode: string, firstName: string, lastName: string, picture: string) => {
  const response = await updateUser({ linkedInCode, firstName, lastName, picture });

  const data: any = response.data;
  const sanitizedMessage = data.message;
  console.log(sanitizedMessage);
};

const getProfile = httpsCallable(functions, "getUserProfile");
export const getUserProfile = async () => {
  const response = await getProfile();

  const data: any = response.data;
  const profile: UserProfile = data.user;
  return profile;
};

const getLinkedInProfileFunction = httpsCallable(
  functions,
  "getLinkedInProfile"
);
export const getLinkedInProfile = async (linkedInCode: string) => {
  const response = await getLinkedInProfileFunction({ code: linkedInCode });

  const data = response.data as LinkedinProfile;
  console.log(JSON.stringify(data));

  return {
    firstName: data.profile.firstName.localized.en_US,
    lastName: data.profile.lastName.localized.en_US,
    picture: data.profile.profilePicture["displayImage~"].elements[0].identifiers[0].identifier
  } as LinkedinUserProfile;
};
