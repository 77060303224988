import React, { useEffect, useState } from "react";
import { useLinkedIn } from "react-linkedin-login-oauth2";

import styles from "./dashboard.module.scss";
import Button from "../../components/Button/Button";

import LinkedIn from "../../assets/images/svg/linkedin.svg";
import { Testimonials } from "./testimonials";

import {
  getLinkedInProfile,
  getUserProfile,
  updateUserProfile,
} from "../../api/user/user-service";
import { AppConfig } from "../../config/config";
import { UserProfile } from "../../core/user";

import { toast } from "react-toastify";
import Loading from "../../components/Loading/Loading";
import Well from "../../components/Well/Well";
import Comments from "../../components/Comments/Comments";

const Dashboard = () => {
  const [profile, setProfile] = useState<UserProfile>({} as UserProfile);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  if (errorMessage && errorMessage !== "") {
    toast.error("Oops! Something went wrong.", {
      toastId: "logout-error",
    });
  }

  useEffect(() => {
    console.log("get user profile");
    const getUserProfileData = async () => {
      const profile = await getUserProfile();
      if (profile) {
        setProfile(profile);
      }
    };

    try {
      setLoading(true);
      getUserProfileData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage("failed to get user profile");
    }
  }, []);

  const updateUser = async (code: string) => {
    console.log("update user");
    try {
      if (code && code !== "") {
        const linkedinUser = await getLinkedInProfile(code);
        console.log(`LINKEDIN USER: ${JSON.stringify(linkedinUser)}`);
        setProfile({
          ...profile,
          linkedInCode: code,
          firstName: linkedinUser.firstName,
          lastName: linkedinUser.lastName,
          picture: linkedinUser.picture,
        });
        setLoading(true);
        await updateUserProfile(
          code,
          linkedinUser.firstName,
          linkedinUser.lastName,
          linkedinUser.picture
        );
        setLoading(false);
        toast.success("Success! 🎉", {
          toastId: "linkedInSuccess",
        });
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMessage(error.message);
    }
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: AppConfig.linkedInConfig.clientId,
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "r_liteprofile r_emailaddress w_member_social",
    onSuccess: (code) => {
      updateUser(code);
      setErrorMessage("");
    },
    onError: (error) => {
      if (error.error !== "user_closed_popup") {
        setErrorMessage(error.errorMessage);
      };
    },
  });

  return (
    <>
      <div className={styles.dashboard__container}>
        {loading && <Loading />}
        {profile.linkedInCode && profile.linkedInCode !== "" ? (
          <div>
            <div className={styles.profileImage}>
              <p>
                Connected as {profile.firstName} {profile.lastName}
              </p>
              <img src={profile.picture} alt='profile' />
            </div>
            <Well>
              <h1> You are on the waiting list</h1>
              <p>
                We got a high influx of users trying to get in. We will email
                you as soon as we’re ready for you!
              </p>
              <p>If you’d like a early demo, book a time with our team here</p>
            </Well>
          </div>
        ) : (
          <>
            <h2>
              Welcome to Lunaa &#x1F44B; <br />
            </h2>
            <h1>
              If you like Linkedin, you'll love Lunaa. <br />
            </h1>
            <p>Join 100+ others who signed up this week.</p>
            <p>Add your linkedin account to get started</p>
            <div className={styles.dashboard__connect}>
              <Button icon={LinkedIn} onClick={linkedInLogin} size='large'>
                Connect your Linkedin{" "}
                <span className={styles.arrow}>&nbsp; &#8594;</span>
              </Button>
              <p className={styles.text__small}>
                We will never post without you explicitly allowing it.
              </p>
            </div>
            <h3>What others have said...</h3>
            <Comments comments={Testimonials} />
            <h3>Got Questions?</h3>
          </>
        )}
        <div className={styles.text__left}>
          <h4>What is Lunaa?</h4>
          <p>
            Luna is the all-in-Linkedin growth tool to help you create, schedule and build relationships off the back of your content on LinkedIn.
          </p>
          <h4>What is your refund policy?</h4>
          <p>You will recieve a full refund if you cancel within 14 Days! No questions asked</p>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
