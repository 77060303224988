export interface FirebaseConfig {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
}

export interface LinkedInConfig {
    clientId: string;
    clientSecret: string;
    redirectUri: string;
}

export interface Config {
    firebaseConfig: FirebaseConfig;  
    linkedInConfig: LinkedInConfig;     
}

export const AppConfig: Config = {
    firebaseConfig: {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
        appId: process.env.REACT_APP_FIREBASE_APP_ID || "",
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "",
    },
    linkedInConfig: {
        clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID || "",
        clientSecret: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET || "",
        redirectUri: process.env.REACT_APP_LINKEDIN_REDIRECT_URI || "",
    },
}