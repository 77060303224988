import React from "react";

import styles from "./well.module.scss";

interface WellProps {
    children: React.ReactNode;
}

const Well = ({ children }: WellProps) => {
  return <div className={styles.well}>{children}</div>;
};

export default Well