import { ErrorType } from './error';
import { SignupError } from './signup-error';

export const validateEmailAndPassword = (email: string, password: string) => {
    const re = /\S+@\S+\.\S+/;
    if (!re.test(email)) {
        throw new SignupError("Invalid Email Format", ErrorType.INVALID_FORMAT_EMAIL);
    }
    if (password.length < 6) {
        throw new SignupError("Invalid Password Format", ErrorType.INVALID_FORMAT_PASSWORD);
    };
    return true;
}