export enum ErrorType {
    INVALID_EMAIL_PASSWORD = "Invalid email or password",
    EMAIL_ALREADY_IN_USE = "You already have an account with us, please login instead",
    INVALID_EMAIL = "Email is invalid",
    INVALID_PASSWORD = "Password is invalid",
    WEAK_PASSWORD = "Password is too weak",
    INTERNAL = "Something went wrong, please try again later",
    INVALID_FORMAT_PASSWORD = "Password must be at least 6 characters long",
    INVALID_FORMAT_EMAIL = "Email is formatted incorrectly",
}

export const checkError = (error: any) => {
    switch(error.code) {
        case "auth/invalid-email":
            return ErrorType.INVALID_EMAIL;
        case "auth/email-already-in-use":
            return ErrorType.EMAIL_ALREADY_IN_USE;
        case "auth/weak-password":
            return ErrorType.WEAK_PASSWORD;
        case "auth/wrong-password":
            return ErrorType.INVALID_PASSWORD;
        case ErrorType.INVALID_FORMAT_PASSWORD:
            return ErrorType.INVALID_FORMAT_PASSWORD;
        case ErrorType.INVALID_FORMAT_EMAIL:
            return ErrorType.INVALID_FORMAT_EMAIL;
        default:
            return ErrorType.INTERNAL;
    }
}