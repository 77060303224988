import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../auth/AuthContext/AuthContext";
import Navbar from "../Navbar/Navbar";

interface ProtectedRouteProps {
  children?: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user } = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || Object.keys(user).length === 0) {
      navigate("/");
    }
  }, [user, navigate]);

  return (
    <>
      <Navbar />
      {children}
    </>
  );
};

export default ProtectedRoute;
