import React from "react";
import "@fontsource/inter";
import "./App.scss";
import { Route, Routes } from "react-router-dom";
import Registration from "./components/Registration/Registration";

import Login from "./components/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import UserAuthContextProvider from "./auth/AuthContext/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PublicRoute from "./components/PublicRoute/PublicRoute";

function App() {
  return (
    <UserAuthContextProvider>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <Routes>
        <Route
          path='/'
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path='/dashboard'
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path='/linkedin' element={<LinkedInCallback />} />
        <Route
          path='/register'
          element={
            <PublicRoute>
              <Registration />
            </PublicRoute>
          }
        />
      </Routes>
    </UserAuthContextProvider>
  );
}

export default App;
